<template>
  <!-- Discover View -->
  <MainLayout
    v-scroll="onScroll"
    scroll-toolbar
    :bg-color="selectedTab === 'ads' ? '' : 'white'"
    class="relative"
  >
    <template #toolbar>
      <div class="flex flex-col w-full">
        <!-- Top header -->
        <div class="relative flex items-center justify-between p-3 border-b border-border-normal">
          <!-- Page label, ad counter -->
          <div class="flex items-center gap-3">
            <img
              src="../../assets/icons/discovery.png"
              class="w-8 h-8"
            >
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted"
            >
              Discovery
            </BaseText>
            <!-- Ad Counter -->
            <div class="flex p-0.5 rounded-md bg-neutral-25">
              <BaseText
                type="body"
                size="xs"
                class="text-text-normal px-1.5 py-1"
              >
                Ad Count
              </BaseText>
              <div class="counter-container px-1.5 rounded bg-white">
                <BaseLoadingSpinnerCircle
                  v-if="!getDiscoveryAdCount"
                  :width="18"
                  :height="18"
                  class="text-icon-normal opacity-30"
                  style="margin-top: 2.5px"
                  :duration="0.9"
                />
                <BaseAnimatedCounter
                  v-else
                  class="text-text-muted text-xs font-semibold"
                  :value="getDiscoveryAdCount"
                  :line-height="24"
                  animate-on-mount
                />
              </div>
            </div>
          </div>

          <!-- Discovery Search Bar -->
          <div class="absolute flex top-1/2 transform -translate-y-1/2" style="left: 308px; right: 308px; z-index: 40001">
            <div class="relative flex-grow mx-auto min-w-0 h-8" style="max-width: 600px">
              <SearchBar 
                :text-search.sync="textSearch"
                :brands-only="selectedTab === 'brands'"
                @submit="handleSearchSubmit"
              />
            </div>
          </div>

          <!-- Create New Button -->
          <BaseCreateNewDropdown 
            primary
            @save="fetchAdvertisements(false)"
          />

        </div>
        <!-- Subview Tabs -->
        <div
          class="flex gap-2 w-full px-3 py-2"
          :class="{'border-b border-border-normal': selectedTab === 'experts'}"
        >
          <router-link
            :to="{ name: 'DiscoveryView'}"
            class="brand-view-tab"
            :class="{'active': selectedTab === 'ads'}"
            style="width: 134px"
          >
            Community Feed
            <transition name="tab">
              <div
                v-if="selectedTab === 'ads'"
                class="brand-tab-underline"
              />
            </transition>
          </router-link>
          <router-link
            :to="{ name: 'DiscoveryBrandsView' }"
            class="brand-view-tab"
            :class="{'active': selectedTab === 'brands'}"
            style="width: 67px"
          >
            Brands
            <transition name="tab">
              <div
                v-if="selectedTab === 'brands'"
                class="brand-tab-underline"
              />
            </transition>
          </router-link>
          <router-link
            :to="{ name: 'DiscoveryExpertsView' }"
            class="brand-view-tab"
            :class="{'active': selectedTab === 'experts'}"
            style="width: 71px"
          >
            Experts
            <transition name="tab">
              <div
                v-if="selectedTab === 'experts'"
                class="brand-tab-underline"
              />
            </transition>
          </router-link>
        </div>
      </div>
    </template>

    <!-- Filter Bar -->
    <template v-if="['ads', 'brands'].includes(selectedTab)" #filter>
      <div ref="filterBar" class="border border-border-normal bg-white bg-opacity-90" style="backdrop-filter: blur(6px);">
        <FilterTopBar
          v-if="selectedTab === 'ads'"
          ref="filterTopBar"
          no-search
          :keyword-search-opened="openKeywordSearch"
          :alphabetical="false"
          :text-search="textSearch"
          :selected-formats.sync="selectedFormats"
          :selected-platforms.sync="selectedPlatforms"
          :selected-niches.sync="selectedNiches"
          :selected-content-filters.sync="selectedContentFilters"
          :selected-market-targets.sync="selectedMarketTargets"
          :selected-date-range.sync="selectedDateRange"
          :sort-order.sync="sortOrder"
          :live-status.sync="liveStatus"
          @change="fetchAdvertisements"
          @resetKeywordSearch="openKeywordSearch = false"
        />
        <FilterTopBar
          v-else-if="selectedTab === 'brands'"
          ref="filterTopBar"
          no-search
          alphabetical
          discovery-brands
          :text-search="textSearch"
          :selected-niches.sync="selectedNiches"
          :favorite-filter.sync="favoriteFilter"
          :sort-order.sync="sortOrder"
          @change="fetchBrands"
        />
      </div>
    </template>

    <template #content>
      <div v-if="selectedTab === 'ads'">
        <!-- Loading State -->
        <BaseLoadingLogo
          v-if="loadingAdvertisements || loadingShuffledAds"
          class="mt-24"
        />

        <!-- Empty State With Filters -->
        <div
          v-else-if="!advertisements.length"
          class="flex flex-col items-center mt-20"
        >
          <img
            src="../../assets/images/empty-with-filters.gif"
            class="border border-gray-400 rounded mx-auto object-cover"
            style="width: 400px; height: 300px"
          >

          <div class="text-lg font-bold my-4">
            Too many filters
          </div>

          <div class="text-center text-gray-700">
            No ads found that meet your current filters
          </div>

          <div class="flex mt-5">
            <BaseButton
              primary
              @click="clearFilters"
            >
              Clear filters
            </BaseButton>
          </div>
        </div>

        <!-- Advertisements List -->
        <AdvertisementList
          v-else
          :discovery="true"
          :advertisements="advertisements"
          :loaded="!loadingAdvertisements"
          @updateAds="updateAdsLocal"
          @getMoreAds="handleMoreAdsRequest"
          @reload="fetchAdvertisements(false)"
        />

        <infinite-loading
          v-if="advertisements.length"
          :identifier="infiniteId"
          @infinite="handleInfiniteLoading"
        >
          <div slot="spinner">
            <BaseLoadingLogo :margin="2" />
          </div>
          <div slot="no-more" />
          <div slot="no-results" />
        </infinite-loading>
        <transition name="shuffleFade">
          <ShuffleButton
            :can-be-shuffled="isAnySelected"
            @shuffle="fetchShuffledAdvertisements(false)"
            @update:selectedLanguages="updateSelectedLanguages"
            @open-keyword-search="toggleKeywordSearchMethod"
          />
        </transition>
      </div>

      <DiscoveryExperts v-else-if="$route.name === 'DiscoveryExpertsView'" />

      <DiscoveryBrands
        v-else
        :text-search="textSearch"
        :update-query="shouldUpdateQuery"
        :favorite-filter="favoriteFilter"
        :selected-niches="selectedNiches"
        :sort-order="sortOrder"
      />

      <TutorialModal
        v-if="showTutorialModal"
        @close="showTutorialModal = false"
      />
      
    </template>
  </MainLayout>
</template>

<script>
import FirebaseAPI from '@/api/firebase'
import InfiniteLoading from 'vue-infinite-loading'
import { mapActions, mapGetters } from 'vuex'

import ForeplayAPI from '@/api/foreplayServer'
import firebase from '../../api/config/FirebaseConfig'
import AdvertisementList from '../../components/advertisements/AdvertisementList'
import MainLayout from '../../layouts/MainLayout'
import SearchBar from '../../components/search/SearchBar.vue'
import DiscoveryBrands from '../../components/discovery/DiscoveryBrands.vue'
import DiscoveryExperts from '../../components/discovery/DiscoveryExperts.vue'
import TutorialModal from '../../components/advertisements/TutorialModal'
import FilterTopBar from '../../components/search/FilterTopBar.vue'
import ShuffleButton from './ShuffleButton.vue'

export default {
  name: 'DiscoveryView',
  components: {
    ShuffleButton,
    AdvertisementList,
    SearchBar,
    DiscoveryBrands,
    DiscoveryExperts,
    FilterTopBar,
    InfiniteLoading,
    TutorialModal,
    MainLayout,
  },
  data () {
    return {
      niches: [],
      advertisements: [],
      loadingAdvertisements: false,
      loadingShuffledAds: false,
      showTutorialModal: false,
      manualUpload: false,
      filtersApplied: false,
      selectedFormats: [],
      selectedNiches: [],
      selectedContentFilters: [],
      selectedLanguages: [],
      selectedMarketTargets: [],
      openKeywordSearch: false,
      selectedDateRange: {
        start: null,
        end: null
      },
      liveStatus: [],
      sortOrder: [],
      selectedPlatforms: [],
      lastDocId: null,
      infiniteId: +new Date(),
      selectedTab: '', // Intitialized in mounted
      docOrder: 'desc',

      // Search Specific
      searchMode: false,
      searchPage: 0,
      searchQuery: '',
      textSearch: '',
      infiniteLoaderState: null,

      // Brands Section
      favoriteFilter: false,
      shouldUpdateQuery: new Date().toString(),
      isShuffled: false
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getUser']),
    ...mapGetters('AdvertisementsModule', ['getAdsBeingSaved']),
    ...mapGetters('RouterModule', ['getLastRoute']),
    ...mapGetters('MiscModule', ['getTheme', 'getDiscoveryAdCount']),
    ...mapGetters('AuthModule', [
      'getTeam',
      'isFreeTier',
      'getUserFreeTrialDate',
      'getUser'
    ]),
    ...mapGetters('AdvertisementsModule', ['getAdsBeingSaved']),
    ...mapGetters('TagsModule', ['getTags']),
    isAnySelected () {
      return (
        this.selectedFormats.length > 0 ||
        this.selectedNiches.length > 0 ||
        this.selectedLanguages.length > 0 ||
        this.selectedPlatforms.length > 0 ||
        this.textSearch !== ''
      )
    }
  },
  created () {
    if (this.$route.query.search) {
      this.textSearch = this.$route.query.search
    }
  },
  async mounted () {
    this.selectedLanguages = this.getUser.defaultLanguages || []

    if (this.$route.name === 'DiscoveryBrandsView') {
      this.selectedTab = 'brands'
      this.sortOrder = [{ name: 'Ads Saved', value: 'saved' }]
      this.niches = await this.getNiches()
    } else if (this.$route.name === 'DiscoveryExpertsView') {
      this.selectedTab = 'experts'
    } else {
      this.selectedTab = 'ads'
      this.sortOrder = this.textSearch.length
        ? [{ name: 'Most Relevant', value: 'relevance' }]
        : [{ name: 'Newest', value: 'desc' }]
      await this.fetchAdvertisements()
      const runAfter = async () => {
        this.niches = await this.getNiches()
        await this.fetchTags()
        await this.getTagAdCounts()
      }

      runAfter()
    }

    // Set initial top bar styles
    if (this.$refs.filterBar) {
      this.$refs.filterBar.style.position = 'sticky'
      this.$refs.filterBar.style.top = '16px'
      this.$refs.filterBar.style.zIndex = '40000'
    }
  },
  methods: {
    // ================================================================================
    // ================================= DATA METHODS =================================
    // ================================================================================

    async getNiches () {
      // Setup
      const db = firebase.firestore()

      const snapshot = await db.collection('niches').get()

      const newDocs = snapshot.docs?.map((doc) => {
        const docData = {
          ...(doc.data() || [])
        }

        return { name: docData.niche, icon: docData.emoji }
      })

      const sortedDocs = newDocs.sort((a, b) => (a.name > b.name ? 1 : -1))
      const otherIndex = sortedDocs.findIndex((d) => d.name === 'Other')
      const otherNiche = sortedDocs.splice(otherIndex, 1)

      sortedDocs.push(otherNiche[0])

      return sortedDocs
    },
    handleSearchSubmit () {
      this.updateRouteQuery()
      this.sortOrder = this.textSearch.length
        ? [{ name: 'Most Relevant', value: 'relevance' }]
        : [{ name: 'Newest', value: 'desc' }]
      if (this.selectedTab === 'ads') {
        this.fetchAdvertisements()
      } else {
        this.fetchBrands()
      }
    },
    updateRouteQuery () {
      if (!this.textSearch.length) {
        window.history.replaceState({}, '', this.$route.path)
        return
      }
      // Update the route's search query oooooeter with the current textSearch value
      const params = new URLSearchParams(window.location.search)
      params.set('search', this.textSearch)
      const newUrl = `${this.$route.path}?${params.toString()}`
      window.history.replaceState({}, '', newUrl)
    },
    handleInfiniteLoading ($state) {
      if (this.isShuffled) {
        this.getMoreShuffledAds($state)
      } else {
        this.getMoreAds($state)
      }
    },
    handleMoreAdsRequest () {
      if (this.isShuffled) {
        this.getMoreShuffledAds({})
      } else {
        this.getMoreAds({})
      }
    },
    async getMoreAds ($state) {
      if (this.trialExpired) {
        return
      }
      if (!this.lastDocId) {
        if (Object.keys($state).length) {
          $state.complete()
        }
        return
      }

      const savedBetweenStart = +new Date(this.selectedDateRange.start) || null
      const savedBetweenEnd = +new Date(this.selectedDateRange.end) || null
      const { results, nextPage } = await ForeplayAPI.Ads.getDiscoveryAds(this.lastDocId, {
        savedBetweenStart,
        savedBetweenEnd,
        textSearch: this.textSearch,
        sort: this.sortOrder[0].value,
        orFilters: {
          formats: this.selectedFormats,
          niches: this.selectedNiches,
          contentFilters: this.selectedContentFilters,
          publisher_platform: this.selectedPlatforms,
          liveStatus: this.liveStatus,
          languages: this.selectedLanguages,
          marketTarget: this.selectedMarketTargets
        }
      })

      this.lastDocId = nextPage

      if (!this.lastDocId) {
        if (Object.keys($state).length) {
          $state.complete()
        }
        return
      }

      this.advertisements = this.advertisements.concat(results)
      if (Object.keys($state).length) {
        $state.loaded()
      }
    },
    async getMoreShuffledAds ($state) {
      if (this.trialExpired) {
        return
      }
      if (!this.lastDocId) {
        if (Object.keys($state).length) {
          $state.complete()
        }
        return
      }

      const savedBetweenStart = +new Date(this.selectedDateRange.start) || null
      const savedBetweenEnd = +new Date(this.selectedDateRange.end) || null
      const { results, nextPage } = await ForeplayAPI.Ads.getShuffledDiscoveryAds(this.lastDocId, {
        savedBetweenStart,
        savedBetweenEnd,
        textSearch: this.textSearch,
        sort: this.sortOrder[0].value,
        orFilters: {
          formats: this.selectedFormats,
          niches: this.selectedNiches,
          contentFilters: this.selectedContentFilters,
          publisher_platform: this.selectedPlatforms,
          liveStatus: this.liveStatus,
          languages: this.selectedLanguages,
          marketTarget: this.selectedMarketTargets
        }
      })

      this.lastDocId = nextPage

      if (!this.lastDocId) {
        if (Object.keys($state).length) {
          $state.complete()
        }
        return
      }

      this.advertisements = this.advertisements.concat(results)
      if (Object.keys($state).length) {
        $state.loaded()
      }
    },
    // Handle Clear Filters
    async clearFilters () {
      const filterTopBar = this.$refs.filterTopBar
      filterTopBar.clearAllFilters()

      await this.fetchAdvertisements()

      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 100)
    },
    async fetchShuffledAdvertisements (load = true) {
      this.loadingShuffledAds = true
      this.advertisements = []
      this.loadingAdvertisements = load

      const savedBetweenStart = +new Date(this.selectedDateRange?.start) || null
      const savedBetweenEnd = +new Date(this.selectedDateRange?.end) || null
      try {
        const { results, nextPage } = await ForeplayAPI.Ads.getShuffledDiscoveryAds(null, {
          savedBetweenStart,
          savedBetweenEnd,
          textSearch: this.textSearch,
          sort: this.sortOrder[0].value,
          orFilters: {
            formats: this.selectedFormats,
            niches: this.selectedNiches,
            contentFilters: this.selectedContentFilters,
            publisher_platform: this.selectedPlatforms,
            liveStatus: this.liveStatus,
            languages: this.selectedLanguages,
            marketTarget: this.selectedMarketTargets
          }
        })

        this.lastDocId = nextPage
        this.advertisements = results
        this.loadingShuffledAds = false
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingAdvertisements = false
        this.infiniteId += 1
      }
      this.isShuffled = true
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 600)
    },
    // Fetch Advertisements
    async fetchAdvertisements (load = true) {
      this.advertisements = []
      this.loadingAdvertisements = load

      const savedBetweenStart = +new Date(this.selectedDateRange?.start) || null
      const savedBetweenEnd = +new Date(this.selectedDateRange?.end) || null
      try {
        const { results, nextPage } = await ForeplayAPI.Ads.getDiscoveryAds(null, {
          savedBetweenStart,
          savedBetweenEnd,
          textSearch: this.textSearch,
          sort: this.sortOrder[0].value,
          orFilters: {
            formats: this.selectedFormats,
            niches: this.selectedNiches,
            contentFilters: this.selectedContentFilters,
            publisher_platform: this.selectedPlatforms,
            liveStatus: this.liveStatus,
            languages: this.selectedLanguages,
            marketTarget: this.selectedMarketTargets
          }
        })

        this.lastDocId = nextPage
        this.advertisements = results
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingAdvertisements = false
        this.infiniteId += 1
      }
      this.isShuffled = false

      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 600)
    },
    updateAdsLocal (adsToUpdate) {
      const duplicateAds = [...this.advertisements]

      adsToUpdate.forEach((ad) => {
        const index = duplicateAds.findIndex((a) => a.id === ad.id)

        if (index !== -1) {
          duplicateAds[index] = ad
        }
      })

      this.advertisements = duplicateAds
    },

    // ================================================================================
    // ================================== UI METHODS ==================================
    // ================================================================================

    toggleKeywordSearchMethod () {
      this.openKeywordSearch = !this.openKeywordSearch
    },
    ...mapActions('BoardsModule', ['fetchBoards']),
    ...mapActions('TagsModule', ['fetchTags', 'getTagAdCounts']),
    fetchBrands () {
      this.shouldUpdateQuery = new Date().toString()
    },
    updateSelectedLanguages (newSelectedLanguages) {
      this.selectedLanguages = newSelectedLanguages
      this.fetchAdvertisements()
    },
    startPosition (position) {
      this.$refs.bottomShadow.classList.add(`start${position}`)
    },
    onScroll (e, position) {
      const marginValue =
        position.scrollTop / 6 < 16 ? position.scrollTop / 6 : 16
      const rounding =
        position.scrollTop / 10 < 5 ? position.scrollTop / 10 : 5
      if (this.$refs.filterBar) {
        this.$refs.filterBar.style.marginLeft = `${marginValue}px`
        this.$refs.filterBar.style.marginRight = `${marginValue}px`
        this.$refs.filterBar.style.marginTop = `${marginValue}px`
        this.$refs.filterBar.style.borderRadius = `${rounding}px`
      }
      const shadowSection = (maxValue) => {
        return position.scrollTop / 15 < maxValue
          ? position.scrollTop / 15
          : maxValue
      }

      if (position.scrollTop && this.$refs.filterBar) {
        this.$refs.filterBar.style.boxShadow = `rgba(0, 0, 0, 0.08) 0px ${shadowSection(
        15
      )}px ${shadowSection(55)}px, rgba(0, 0, 0, 0.08) 0px -${shadowSection(
        7
      )}px ${shadowSection(
        10
      )}px, rgba(0, 0, 0, 0.08) 0px 4px 6px, rgba(0, 0, 0, 0.08) 0px ${shadowSection(
        7
      )}px ${shadowSection(13)}px, rgba(0, 0, 0, 0.08) 0px -${shadowSection(
        3
      )}px ${shadowSection(5)}px`
      // this.$refs.filterBar.style.boxShadow = `rgba(58, 111, 251, 0.4) -${shadowSection(5)}px ${shadowSection(5)}px, rgba(58, 111, 251, 0.3) -${shadowSection(10)}px ${shadowSection(10)}px, rgba(58, 111, 251, 0.2) -${shadowSection(15)}px ${shadowSection(15)}px, rgba(58, 111, 251, 0.1) -${shadowSection(20)}px ${shadowSection(20)}px, rgba(58, 111, 251, 0.05) -${shadowSection(25)}px ${shadowSection(25)}px`
      } else {
        if (this.$refs.filterBar) {
          this.$refs.filterBar.style.boxShadow = null
        }
      }
    },
    moveShadowRight () {
      this.$refs.bottomShadow.classList.add('moveRight')

      this.$refs.bottomShadow.classList.remove('moveLeft')
      this.$refs.bottomShadow.classList.remove('moveCenter')
    },
    moveShadowCenter () {
      this.$refs.bottomShadow.classList.add('moveCenter')

      this.$refs.bottomShadow.classList.remove('moveLeft')
      this.$refs.bottomShadow.classList.remove('moveRight')
    },
    moveShadowLeft () {
      this.$refs.bottomShadow.classList.add('moveLeft')

      this.$refs.bottomShadow.classList.remove('moveRight')
      this.$refs.bottomShadow.classList.remove('moveCenter')
    },
    updateFilter (filterName, items) {
      this[filterName] = items
    },
    toggleFavoriteFilter () {
      this.favoriteFilter = !this.favoriteFilter
    }
  },
  metaInfo: {
    title: 'Foreplay.co | Discovery'
  }
}
</script>

<style scoped>
.brand-view-tab {
  position: relative;
  padding: 0.375rem 0.625rem;
  border-radius: 0.375rem;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #5E6678; /* text-text-normal */
  background-color: transparent;
  cursor: pointer;
  user-select: none; /* Standard */
  transition: color 150ms ease-in-out, background-color 150ms ease-in-out;
  white-space: nowrap;
}
.brand-view-tab:hover {
  color: #303546; /* text-text-muted */
  background-color: #F6F8FA; /* bg-neutral-25 */
}
.brand-view-tab.active {
  color: #303546; /* text-text-muted */
  background-color: transparent;
  font-weight: 500;
}
.brand-tab-underline {
  position: absolute;
  top: calc(100% + 0.5rem);
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 0px;
  z-index: 40001;
  border-bottom: 1px solid #06070F; /* border-icon-loud */
}
.counter-container {
  box-shadow: 0px 1px 1px -0.5px rgba(26, 48, 84, 0.04), 0px 2px 2px -1px rgba(26, 48, 84, 0.03), 0px 3px 3px -1.5px rgba(26, 48, 84, 0.03), 0px 5px 5px -2.5px rgba(26, 48, 84, 0.02), 0px 8px 8px -4px rgba(26, 48, 84, 0.02);
}

.shadow-elem {
  display: flex;
  justify-content: center;
  width: 34%;
}

.tool-el {
  /* Right */
  --right-width: 25%;
  --right-translate: 72%;

  /* Center */
  --center-width: 25%;
  --center-translate: 39%;

  /* Left */
  --left-width: 34%;
  --left-translate: 0%;
}

/* Right */
.moveRight {
  animation: moveRightAnimation 0.5s ease-out;
  animation-fill-mode: forwards;
}

.moveRight div {
  animation: rightWidthAnimation 0.5s ease-out;
  animation-fill-mode: forwards;
}

.startRight {
  transform: translateX(var(--right-translate));
}

.startRight div {
  width: var(--right-width)
}

/* Center */
.moveCenter {
  animation: moveCenterAnimation 0.5s ease-out;
  animation-fill-mode: forwards;
}

.moveCenter div {
  animation: centerWidthAnimation 0.5s ease-out;
  animation-fill-mode: forwards;
}

.startCenter {
  transform: translateX(var(--center-translate));
}

.startCenter div {
  width: var(--center-width)
}

/* Left */
.moveLeft {
  animation: moveLeftAnimation 0.5s ease-out;
  animation-fill-mode: forwards;
}

.moveLeft div {
  animation: leftWidthAnimation 0.5s ease-out;
  animation-fill-mode: forwards;
}

.startLeft {
  transform: translateX(var(--left-translate));
}

.startLeft div {
  width: var(--left-width)
}
.shuffleFade-enter-active, .shuffleFade-leave-active {
  transition: opacity 0.3s;
}
.shuffleFade-enter, .shuffleFade-leave-to {
  opacity: 0;
}

/* Rigth Animations */
@keyframes moveRightAnimation {
  to {
    transform: translateX(var(--right-translate));
  }
}

@keyframes rightWidthAnimation {
  to {
    width: var(--right-width)
  }
}

/* Center Animations */
@keyframes moveCenterAnimation {
  to {
    transform: translateX(var(--center-translate));
  }
}

@keyframes centerWidthAnimation {
  to {
    width: var(--center-width)
  }
}

/* Left Animations */
@keyframes moveLeftAnimation {
  to {
    transform: translateX(var(--left-translate));
  }
}

@keyframes leftWidthAnimation {
  to {
    width: var(--left-width)
  }
}

/* Vue <transition> classes */
.tab-enter-active, .tab-leave-active {
  transition: width 150ms ease-in-out;
}
.tab-enter-from, .tab-enter, .tab-leave-to {
  width: 0;
}
.tab-enter-to, .tab-leave-from {
  width: 100%;
}
</style>
